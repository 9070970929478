import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.partners-carousel__carousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        loop: true,
        rewind: true,
        centerInsufficientSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        autoplay: {
          delay: 2000,
        },
      });
    });
  }
});
